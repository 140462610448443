import React from "react"
import PageHeading from "../../utils/page-heading"
import { StaticImage } from "gatsby-plugin-image"

const JoinAsColumnistIntro = () => {
  return (
    <>
      <StaticImage
        src="../../../images/ColumnistPage.png"
        placeholder="blurred"
        className="w-full"
      />

      <PageHeading text="Join as a Columnist" />
      <p>
        Do you think you have what it takes to write a column routinely on
        DearDesigner? Get in touch with us today. Authors are free to explore a
        range of themes for their columns. However, selected themes should be
        content relatable to design community.
      </p>
      <p>
        Applicants would need to show consistency with article submission for a probational period
        before full confirmation as a columnist on DearDesigner.
      </p>
    </>
  )
}

export default JoinAsColumnistIntro
