import React from "react"
import TitleText from "../../about-us/title-text"
import SubmitButton from "../../../utils/button/submit"
import useForm from "../../../../hooks/useForm"
import FormSuccess from "../../../utils/form/success"
import FormError from "../../../utils/form/error"

const JoinAsColumnistApplication = () => {
  const { onSubmit, isLoading, isError, isSuccess, errorMessage } = useForm();

  return (
    <>
      <TitleText title="Apply Here" />

      <form
        name="columnist"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={onSubmit}
      >
        <input type="hidden" name="form-name" value="columnist" />
        <div className="form-wrapper">
          {/* Full Name */}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="fullname"
              placeholder="Full Name"
            />
          </div>

          {/* Email Address */}
          <div className="form-group">
            <input
              required="required"
              type="email"
              name="emailAddress"
              placeholder="Email Address"
            />
          </div>

          {/* Which subject would you like to write on?*/}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="subjectToWriteOn"
              placeholder="Which subject would you like to write on?"
            />
          </div>

          {/* How frequently can you do this? */}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="frequency"
              placeholder="How frequently can you do this?"
            />
          </div>

          {/* Any other thing you want us to know */}
          <div className="form-group col-span-2">
            <input
              type="text"
              required="required"
              name="anyOtherThing"
              placeholder="Any other thing you want us to know"
            />
          </div>

          <div className="form-group">
            <SubmitButton disabled={isLoading}/>
          </div>
        </div>
      </form>
      <div>
        {isSuccess && <FormSuccess message="Thanks for your interest! Expect a feedback from us within 1–4 business days."/>}
        {isError && <FormError message={errorMessage}/>}
      </div>
    </>
  )
}

export default JoinAsColumnistApplication
