import React from "react"
import PagesLayout from "../components/layout/pages"
import JoinAsColumnistIntro from "../components/pages/columnist/intro"
import JoinAsColumnistApplication from "../components/pages/columnist/application"

const JoinAsColumnist = () => {
  return (
    <PagesLayout title="Join as a Columnist">
      <JoinAsColumnistIntro />
      <JoinAsColumnistApplication />
    </PagesLayout>
  )
}

export default JoinAsColumnist
